export var Category;
(function (Category) {
    Category["Image"] = "Image";
    Category["Button"] = "Button";
    Category["ARModel"] = "ARModel";
})(Category || (Category = {}));
export var Action;
(function (Action) {
    Action["Load"] = "Load";
    Action["Click"] = "Click";
    Action["Drag"] = "Drag";
})(Action || (Action = {}));
