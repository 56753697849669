<script lang="ts">
	// @ts-check
	import { onMount } from 'svelte';
	import ControllerSelect, { STATES, models } from './components/ControllerSelector.svelte';
	import ISiteEventHubService from './shared/services/event-hub.service';
	import uuid from 'uuid-v4';

	const sessionIdentifier = uuid();
	let iSiteEventHubService = new ISiteEventHubService();
	onMount(() => {
		iSiteEventHubService.logLaunchEvent({ uuid: sessionIdentifier });
	});

	let currentState = STATES.NOCONTROLLER;

	// Add urls...
	const controllerUrlMap = new Map();

	function handleControllerSelected(e) {
		currentState = e.detail.state;
		iSiteEventHubService.logControllerButtonClick({
			uuid: sessionIdentifier,
			value: Object.values(STATES)[currentState]
		});
	}

	function handleFirstModelInteraction() {
		iSiteEventHubService.logModelInteraction({
			uuid: sessionIdentifier,
			value: 'Camera Change'
		});
	}

	function handleLoad(e) {
		iSiteEventHubService.logModelLoadEvent({
			uuid: sessionIdentifier,
			value: e.detail
		});
	}
</script>

<main>
	<ControllerSelect on:controllerSelected={handleControllerSelected} />
</main>

<style global lang="postcss">
	@tailwind base;
	@tailwind components;
	@tailwind utilities;
</style>
