import { Action, Category } from "../enums/enums";
import ISiteActionEventData from "../models/isite-action-event.model";
import ISiteEvent from "../models/isite-event.model";
import ISiteLaunchEventData from "../models/isite-launch-event.model";
import ISiteLaunchEvent from "../models/isite-launch-event.model";
let env = process.env;
const isProd = JSON.stringify(env) === "production";
const eventHubUrl = isProd
    ? "https://events.isitetv.com/api/Event"
    : "https://eventstest.isitetv.com/api/Event";
export default class ISiteEventHubService {
    constructor() {
        this.pageLocation = "EXG Cable Guys";
    }
    logLaunchEvent(config) {
        config.action = Action.Load;
        config.location = this.pageLocation;
        let launchEventData = new ISiteLaunchEvent(config.uuid, config.location, "skuId");
        this.postEventData(launchEventData);
    }
    logModelLoadEvent(config) {
        config.action = Action.Load;
        config.category = Category.ARModel;
        const eventData = new ISiteActionEventData(config.uuid, config.category, config.action, config.value);
        this.postEventData(eventData);
    }
    logControllerButtonClick(config) {
        config.category = Category.ARModel;
        config.location = this.pageLocation;
        this.logClickEvent(config);
    }
    logClickEvent(config) {
        config.action = Action.Click;
        if (config && config.category && config.action) {
            const eventData = new ISiteActionEventData(config.uuid, config.category, config.action, config.value);
            this.postEventData(eventData);
        }
    }
    logDragEvent(config) {
        config.action = Action.Drag;
        if (config && config.category && config.action) {
            const eventData = new ISiteActionEventData(config.uuid, config.category, config.action, config.value);
            this.postEventData(eventData);
        }
    }
    logModelInteraction(config) {
        config.category = Category.ARModel;
        config.location = this.pageLocation;
        this.logDragEvent(config);
    }
    postEventData(eventData) {
        const event = new ISiteEvent();
        event.application = ISiteEventHubService.application;
        if (eventData instanceof ISiteLaunchEventData) {
            event.documentType = "Log Start";
            event.country = "";
            event.userAgent = "";
            event.referrer = "";
            event.internalEvent = 0;
        }
        else {
            event.documentType = ISiteEventHubService.interaction;
        }
        event.data = eventData;
        this.iSiteEventPostRequest(event);
    }
    iSiteEventPostRequest(event) {
        fetch(eventHubUrl, {
            method: "POST",
            body: JSON.stringify(event),
        }).catch((e) => {
            console.log(e);
        });
    }
}
ISiteEventHubService.application = "Syndication";
ISiteEventHubService.interaction = "Interaction";
ISiteEventHubService.environment = "Interactive Player";
